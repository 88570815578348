import "@ionic/vue/css/ionic.bundle.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

import "@/css/ionic.colors.css";
import "@/css/ionic.extensions.css";
import "@/css/style.css";

import router from "./router";
import store from "./store";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { IonicVue } from "@ionic/vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import i18nDirective from "@/directives/i18n";

import App from "./App.vue";
import BasePage from "@/components/BasePage.vue";
import "./registerServiceWorker";

import { addIcons } from "ionicons";
import * as icons from "ionicons/icons";

import * as verifyToken from "seedgreen-shared/query/verify-token";
import { mobileQueryClient } from "@/lib/query-client";

verifyToken.configure({
	redirectExtension: "",
	loginPageUrl: "/#/login",
});
import { i18n } from "./lib/i18n";

import * as Sentry from "@sentry/vue";

function camelCaseToDash(str: string) {
	return str.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();
}

// Load all ionic icons
addIcons(
	Object.entries(icons).reduce((acc: { [key: string]: string }, entry) => {
		const name = camelCaseToDash(entry[0]); // Convert to dash case

		acc[`ios-${name}`] = entry[1];
		acc[`md-${name}`] = entry[1];

		return acc;
	}, {}),
);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
	.use(IonicVue)
	.use(pinia)
	.use(store)
	.use(router)
	.use(i18n)
	.use(VueQueryPlugin, {
		queryClient: mobileQueryClient,
	})
	.directive("i18n", i18nDirective)
	.component("base-page", BasePage);

Sentry.init({
	app,
	// if this environment variable is not set, Sentry will not be initialized. This is useful for local development. DO NOT SET LOCALLY.
	dsn: process.env.VUE_APP_SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration({
			maskAllText: false,
		}),
		Sentry.browserProfilingIntegration(),
	],
	release: process.env.VUE_APP_SENTRY_RELEASE,
	environment: process.env.VUE_APP_DEPLOYED_ENV ?? "local",
	// Tracing
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", "m.seedgreen.gva.local", "m.seedgreen.gva-dev.ai", "m.seedgreen.gva.ai"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	// Profiling
	profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});

router.isReady().then(() => {
	app.mount("#app");
});
